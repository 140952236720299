// extracted by mini-css-extract-plugin
export const top = "demo-desktop-module--top--O93Uw";
export const main = "demo-desktop-module--main--ld1oZ";
export const detailsEditor = "demo-desktop-module--detailsEditor--A-XYu";
export const codeEditor = "demo-desktop-module--codeEditor--c2-82";
export const codeEditorContainer = "demo-desktop-module--codeEditorContainer--SDNu7";
export const codeEditorsContainer = "demo-desktop-module--codeEditorsContainer--Jekqh";
export const alert = "demo-desktop-module--alert--wGfQR";
export const generateAndRunTestsButton = "demo-desktop-module--generateAndRunTestsButton--AS+4l";
export const toolbarContainer = "demo-desktop-module--toolbarContainer--N4X-E";
export const copyLinkButton = "demo-desktop-module--copyLinkButton--7mEPs";
export const dropdownLanguages = "demo-desktop-module--dropdownLanguages--IGWvq";
export const dropdownContainer = "demo-desktop-module--dropdownContainer--ZbBcw";